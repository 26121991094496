
import { defineComponent, onMounted, reactive, ref } from 'vue';

import UserService from '@/services/user.service';
import CreateUserRequest from '@/requests/create-user.request';
import UpdateUserRequest from '@/requests/update-user.request';

import Layout from '@/components/Layout.vue';
import Loader from '@/components/Loader.vue';
import SVG from '@/components/SVG.vue';
import Input from '@/components/form/Input.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import Store from '@/store';

export default defineComponent({
	components: {
		Loader,
		SVG,
		Input,
		Checkbox,
		Layout,
	},

	setup() {
		const user = Store.get('user');
		const errorMessage = ref('');
		const showError = ref(false);
		const showUserLink = ref(false);
		const copyMessageOpen = ref(false);
		const copyMessage = ref('');
		const userLink = ref('');
		const form: CreateUserRequest = reactive({
			first_name: '',
			last_name: '',
			email: '',
			phone: '',
			password: '',
			password_confirmation: '',
			role_id: null,
			wants_emails: false,
		});

		const loading = ref(false);
		const loadingLink = ref(false);

		onMounted(() => {
			if (user.value !== null && user.value !== undefined) {
				form.first_name = user.value.first_name;
				form.last_name = user.value.last_name;
				form.email = user.value.email;
				form.phone = user.value.phone;
				form.wants_emails = !!user.value.wants_emails;

				if (user.value.link) {
					const url = `${process.env.VUE_APP_URL}/link/${user.value.link.uuid}`;

					showUserLink.value = true;
					userLink.value = url;
				}
				return;
			}

			showError.value = false;
			showUserLink.value = false;
			copyMessageOpen.value = false;
		});

		function copyToClickBoard() {
			navigator.clipboard
				.writeText(userLink.value)
				.then(() => {
					copyMessageOpen.value = true;
					copyMessage.value = 'Leinkjan er kopiera';

					setTimeout(() => {
						copyMessageOpen.value = false;
					}, 2000);
				})
				.catch(() => {
					copyMessageOpen.value = true;
					copyMessage.value = 'Tað eydnaðist ikki at kopiera leinkjuna:<br>' + userLink.value;
				});
		}

		async function update() {
			if (loading.value) {
				return;
			}

			errorMessage.value = '';
			showError.value = false;

			loading.value = true;

			const userRequestObject: UpdateUserRequest = {
				first_name: form.first_name,
				last_name: form.last_name,
				email: form.email,
				phone: form.phone,
				wants_emails: form.wants_emails,
			};

			if (!form.password || form.password.length > 0 || form.password_confirmation) {
				if (form.password && form.password.length < 8) {
					errorMessage.value = 'Loyniorðið skal vera minst 8 stavir';
					showError.value = true;

					loading.value = false;
					return;
				}

				if (form.password !== form.password_confirmation) {
					errorMessage.value = 'Loyniorðini skulu vera líka';
					showError.value = true;

					loading.value = false;
					return;
				}

				userRequestObject.password = form.password;
				userRequestObject.password_confirmation = form.password_confirmation;
			}

			await UserService.update(user.value.id, userRequestObject)
				.then((response) => {
					if (response?.status === 'fail') {
						errorMessage.value = response.message;
						showError.value = true;
						return;
					}

					Store.set('user', { ...Store.get('user').value, ...response });

					form.password = '';
					form.password_confirmation = '';
				})
				.finally(() => {
					loading.value = false;
				});
		}

		return {
			// data
			form,
			loading,
			loadingLink,
			errorMessage,
			showError,
			showUserLink,
			userLink,
			copyMessage,
			copyMessageOpen,

			// functions
			copyToClickBoard,
			update,
		};
	},
});
